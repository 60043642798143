const host = window.location.hostname;
const isLive = window.location.hostname === 'app.investus.world';

let base_url;
let path;
let img_path;
let imge;
let assets;
let root;
let admin_path;
let path_realestate;
let tinyapikey;


if (host == 'localhost' || host == 'offlinepartner.mag.holdings' || host=='offlinefranchise.mag.holdings') {

  // base_url = "https://partnerapi.mag.holdings/api/";
  // path = "https://partnerapi.mag.holdings/";
  // img_path = "https://partnerapi.mag.holdings/Personal/MemberImages/";
  // imge = "https://partnerapi.mag.holdings/personal/memberimages/kyc/";
  // assets = "https://partnerapi.mag.holdings/assets/";
  // root = "https://partnerapi.mag.holdings/";


    tinyapikey = "6o1149t4fhc3r0pkpyiiu5pdr5exjqzb24dj3dwdopm6nb3v";

    base_url = "https://offlinepartnerapi.mag.holdings/api/";
    path = "https://offlineapi.magstore.world/";
    img_path = "https://offlineapi.magstore.world/Personal/MemberImages/";
    imge = "https://offlineapi.magstore.world/personal/memberimages/kyc/";
    assets = "https://offlineapi.magstore.world/assets/";
    root = "https://offlineapi.magstore.world/";
    admin_path = "https://downloadstest.magstore.world/";
    path_realestate = "https://offlineapi.magstore.world/images/RealEstate/";


    // base_url = "http://localhost:52476/api/";
    // path = "http://localhost:52476/";
    // img_path = "http://localhost:52476/Personal/MemberImages/";
    // imge = "http://localhost:52476/personal/memberimages/kyc/";
    // assets = "http://localhost:52476/assets/";
    // root = "http://localhost:52476/";
    // admin_path = "http://localhost:52476/";
    // path_realestate = "http://localhost:52476//images/RealEstate/";


}
else
{
  base_url = "https://partnerapi.mag.holdings/api/";
  path = "https://partnerapi.mag.holdings/";
  img_path = "https://partnerapi.mag.holdings/Personal/MemberImages/";
  imge = "https://partnerapi.mag.holdings/personal/memberimages/kyc/";
  assets = "https://partnerapi.mag.holdings/assets/";
  root = "https://partnerapi.mag.holdings/";
  tinyapikey = "6o1149t4fhc3r0pkpyiiu5pdr5exjqzb24dj3dwdopm6nb3v";
}


const Config = {
  base_url,
  path,
  img_path,
  tinyapikey,
  imge,
  assets,
  root,
  admin_path,
  path_realestate,
  date_format: "DD/MM/YYYY",
  date_format_input: "YYYY-MM-DD",
};


export default Config;